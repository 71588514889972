import * as React from 'react'
import Layout from '../components/layout/index'
import {
  Hero,
  Features,
  Pricing,
  Faq,
  Testimonial,
  Contact,
  Waitlist,
  Resources,
  Security,
} from '../components/sections/index'
import Seo from '../components/seo'
import MetaImage from '../../static/assets/images/trustlokr-meta-image.jpg'

const IndexPage = () => {
  return (
    <Layout>
      <Seo title='TrustLoKR' image={MetaImage} description='' />
      <Hero />
      <Features />
      <Pricing />
      <Faq />
      <Testimonial />
      <Security/>
      <Resources/>
      <Waitlist />
      {/* <Contact /> */}
    </Layout>
  )
}

export default IndexPage
