import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  image: metaImage,
  type,
  createdAt,
  updatedAt,
  url,
}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription =
          description || data.site.siteMetadata.description
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s`}
            meta={[
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:url`,
                content: url,
              },
              {
                name: 'robots',
                content: 'index, follow',
              },
              {
                name: 'googlebot',
                content:
                  'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1',
              },
              {
                name: 'bingbot',
                content:
                  'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1',
              },
              {
                name: `description`,
                content: metaDescription,
              },
              {
                name: `og:site_name`,
                content: title,
              },
              {
                property: `og:image`,
                content: `${data.site.siteMetadata.siteUrl}${metaImage}`,
              },
              {
                property: `og:type`,
                content: type || `website`,
              },
              {
                property: `og:updated_time`,
                content: updatedAt,
              },
              {
                property: `og:published_time`,
                content: createdAt,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `twitter:image`,
                content: `${data.site.siteMetadata.siteUrl}${metaImage}`,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords,
                    }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.string,
  type: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
  }
`
